@import url("https://fonts.googleapis.com/css2?family=Abel&family=Almarai:wght@300&family=Archivo+Black&family=Candal&family=Crimson+Text&family=Dancing+Script:wght@500&family=Instrument+Serif&family=Italianno&family=Paytone+One&family=Philosopher&family=Roboto+Mono:wght@500&family=Varela&family=Young+Serif&display=swap");
@import "~bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&family=Candal&family=Crimson+Text&family=Dancing+Script:wght@500&family=Instrument+Serif&family=Italianno&family=Paytone+One&family=Philosopher&family=Roboto+Mono:wght@500&family=Varela&family=Young+Serif&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Almarai:wght@300&family=Archivo+Black&family=Candal&family=Crimson+Text&family=Dancing+Script:wght@500&family=Instrument+Serif&family=Italianno&family=Manrope&family=Montserrat:wght@200;300&family=Paytone+One&family=Philosopher&family=Roboto+Mono:wght@500&family=Varela&family=Young+Serif&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Almarai:wght@300&family=Archivo+Black&family=Candal&family=Crimson+Text&family=Dancing+Script:wght@500&family=Instrument+Serif&family=Italianno&family=Montserrat:wght@200;300&family=Paytone+One&family=Philosopher&family=Roboto+Mono:wght@500&family=Varela&family=Young+Serif&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abel&family=Almarai:wght@300&family=Archivo+Black&family=Candal&family=Crimson+Text&family=Dancing+Script:wght@500&family=Instrument+Serif&family=Italianno&family=Montserrat:wght@200;300&family=Paytone+One&family=Philosopher&family=Roboto+Mono:wght@500&family=Rubik:wght@300&family=Varela&family=Young+Serif&display=swap");
@import "../node_modules/animate.css/animate.min.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

.carousel-indicators {
  bottom: -65px;
  color: #f4eded;
}
.carousel-control-prev-icon {
  margin-left: -150px;
  border-radius: 17px;
}
.carousel-control-next-icon {
  margin-left: 117px;
  border-radius: 17px;
}
@media (max-width: 767px) {
  * {
    overflow-x: hidden;
  }
}
